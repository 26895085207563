// import react and gatsby libs
import React from 'react'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// styled Main element
const Main = styled.main`
  padding: 1rem;
  color: #222;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    padding: 2rem 2rem 4rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    padding: 4rem 8rem 4rem;
  `}

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    line-height: 4rem;
    font-family: 'Glyph';
    font-weight: 300;
  }

  p {
    line-height: 1.75rem;
  }
`

// export Main component
export default ({ children }) => (
  <Main>{children}</Main>
)
