// import react and gatsby libs
import React from 'react'
import { graphql } from 'gatsby'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'
import Background from '../assets/TableBG.jpg'

// import components
import Head from '../components/Head'
import Layout from '../components/Layout'
import Navigation from '../components/Navigation'
import Main from '../components/Main'
import BannerImage from '../components/BannerImage'
import Footer from '../components/Footer'

// styled Content element
const Content = styled.div`
  background-color: #fff;
  /*background-image: url(${Background});*/
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    width: 70vw;
    margin-left: 30vw;
    float: left;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    width: 80vw;
    margin-left: 20vw;
  `}
`


// styled Article element
const Article = styled.article`

  iframe {
    border: 0;
  }

  .column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-auto-flow: row;
    /*grid-auto-rows: 1fr;*/
    text-align: center;

    p {
      margin: 0 0 1rem;
      line-height: 1.5rem;
    }
  }

  a {
    color: #0ebece;
    text-decoration: none;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    padding: 0 4rem;
  `}

`

// export pageTemplate component
export default function Template({
  data,
}) {
  // get vars
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return(
    <Layout>
      <Head
        title={`${frontmatter.title} – How2charist`} />
      <Navigation />
      <Content>
        <Main>
          <BannerImage
            image={frontmatter.banner_image}
            alt={frontmatter.title} />
          <Article dangerouslySetInnerHTML={{ __html: html }} />
        </Main>
        <Footer />
      </Content>
    </Layout>
  )
}

// page query
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        banner_image
      }
    }
  }
`
